var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ecommerce-wishlist-demo" } },
    [
      _vm.wishListitems.length
        ? _c(
            "div",
            {
              staticClass: "items-grid-view vx-row match-height",
              attrs: { appear: "" },
            },
            _vm._l(_vm.wishListitems, function (item) {
              return _c(
                "div",
                {
                  key: item.objectID,
                  staticClass: "vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full",
                },
                [
                  _c(
                    "item-grid-view",
                    { attrs: { item: item } },
                    [
                      _c("template", { slot: "action-buttons" }, [
                        _c("div", { staticClass: "flex flex-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "item-view-primary-action-btn p-3 flex flex-grow items-center justify-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.removeItemFromWishList(item)
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "XIcon", svgClasses: "h-4 w-4" },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-sm font-semibold ml-2" },
                                [_vm._v("REMOVE")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "item-view-secondary-action-btn bg-primary p-3 flex flex-grow items-center justify-center text-white cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.cartButtonClicked(item)
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ShoppingBagIcon",
                                  svgClasses: "h-4 w-4",
                                },
                              }),
                              _vm.isInCart(item.objectID)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text-sm font-semibold ml-2",
                                    },
                                    [_vm._v("VIEW IN CART")]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "text-sm font-semibold ml-2",
                                    },
                                    [_vm._v("MOVE TO CART")]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c(
            "vx-card",
            { attrs: { title: "You don't have any items in your wish list." } },
            [
              _c(
                "vs-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.$router.push("/apps/eCommerce/shop").catch(() => {})
                    },
                  },
                },
                [_vm._v("Browse Shop")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }